import { type LoaderFunctionArgs, json } from '@remix-run/node'
import { Link, Outlet } from '@remix-run/react'
import { UserDropdown } from '#app/components/sidebar/app-main-sidebar.tsx'
import { Button } from '#app/components/ui/button.tsx'
import { Logo } from '#app/root.tsx'
import { getUserId } from '#app/utils/auth.server.ts'
import { redirectUserWithRole } from '#app/utils/permissions.server.ts'
import { useOptionalUser } from '#app/utils/user.ts'

export async function loader({ request }: LoaderFunctionArgs) {
	const userId = await getUserId(request)

	if (userId) {
		await redirectUserWithRole(userId, [
			{ roleName: 'admin', redirectUrl: '/app' },
			{ roleName: 'staff', redirectUrl: '/app' },
			{ roleName: 'doctor', redirectUrl: `/doc/${userId}` },
		])
	}

	return json({})
}

export default function MarketingLayout() {
	const user = useOptionalUser()

	return (
		<>
			<header className="border-b p-6">
				<nav className="flex items-center justify-between">
					<Logo />

					<div className="w-fit">
						{user ? (
							<UserDropdown />
						) : (
							<Button asChild variant="default" size="lg">
								<Link to="/login">Log In</Link>
							</Button>
						)}
					</div>
				</nav>
			</header>

			<Outlet />
		</>
	)
}
